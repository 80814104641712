import { UserAccessLevelOrLocationsStringWithTooltip } from '@traba/react-components'
import { USER_ROLE_NAMES, UserAccessLevel } from '@traba/types'
import {
  assignedActiveLocationsForMember,
  isBizMemberCompanyWide,
} from '@traba/utils'
import { useCallback } from 'react'
import { Table, Td, Tr } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import { UserRole, UserWithRole } from 'src/types'
import { formatPhoneNumber } from 'src/utils/phoneNumberUtils'
import { EditActiveMembersDropdownMenu } from '../EditActiveMembersDropdownMenu'
import { MemberRoleInfoButton } from '../MemberRoleInfoButton'
import { StyledTdEditMenuContainer } from '../MembersProfile.styles'
import { MembersProfilePermission } from '../MembersProfilePermission'

interface ActiveMembersMenuProps {
  member: UserWithRole
}

function ActiveMembersMenu({ member }: ActiveMembersMenuProps) {
  return (
    <StyledTdEditMenuContainer>
      <EditActiveMembersDropdownMenu member={member} />
    </StyledTdEditMenuContainer>
  )
}

interface ActiveMembersTableProps {
  members: UserWithRole[]
  userCanEdit: boolean
  isLoading?: boolean
}

export function ActiveMembersTable(props: ActiveMembersTableProps) {
  const userContext = useUserContext()
  const { hotSettings } = useHotSettings()

  const self = userContext.state.userProfile?.uid
  const adminCount = props.members.filter(
    (m) => m.role === UserRole.Admin,
  ).length

  const canEditMember = useCallback(
    (member: UserWithRole) => {
      const isLocationAssignedUserEditingCompanyWide =
        userContext.state.userProfile?.userAccessLevel ===
          UserAccessLevel.LOCATIONS_ASSIGNED &&
        member.userAccessLevel === UserAccessLevel.COMPANY_WIDE

      const isEditingSelf = self === member.uid
      const isLastAdmin = adminCount === 1 && member.role === UserRole.Admin

      return (
        props.userCanEdit &&
        !isLocationAssignedUserEditingCompanyWide &&
        !isEditingSelf &&
        !isLastAdmin
      )
    },
    [
      userContext.state.userProfile?.userAccessLevel,
      self,
      adminCount,
      props.userCanEdit,
    ],
  )

  return (
    <div>
      <Table
        headers={[
          'Name',
          'Email',
          'Phone',
          'Role',
          ...(hotSettings?.enableRegionalAccessPhase2 ? ['Locations'] : []),
          ...(hotSettings?.enableNotificationPreferencesV2
            ? []
            : ['Permissions']),
          ...(props.userCanEdit ? [''] : []),
        ]}
        itemType="members"
        showEmptyState
        isLoading={props.isLoading}
      >
        {props.members.map((member) => (
          <Tr key={member.uid}>
            <Td>
              {member.firstName} {member.lastName}
              {self === member.uid ? ' (You)' : ''}
            </Td>
            <Td>{member.email}</Td>
            <Td>
              {member.phoneNumber
                ? formatPhoneNumber(member.phoneNumber, true)
                : ''}
            </Td>
            <Td>{USER_ROLE_NAMES[member.role]}</Td>
            {hotSettings?.enableRegionalAccessPhase2 && (
              <Td>
                <UserAccessLevelOrLocationsStringWithTooltip
                  isCompanyWide={isBizMemberCompanyWide(member)}
                  assignedLocations={assignedActiveLocationsForMember(member)}
                />
              </Td>
            )}
            {!hotSettings?.enableNotificationPreferencesV2 && (
              <Td>
                <MembersProfilePermission member={member} />
              </Td>
            )}
            {canEditMember(member) && <ActiveMembersMenu member={member} />}
          </Tr>
        ))}
      </Table>
      <MemberRoleInfoButton
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xs,
        }}
      />
    </div>
  )
}

import {
  GroupedLocationSingleSearchSelector,
  SELECT_ALL_OPTION_ID,
} from '@traba/react-components'
import { COMPANY_WIDE_ID } from '@traba/types'
import { useCallback, useEffect } from 'react'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'

export const SELECT_LOCATION_TEXT = 'All locations'

interface LocationSingleSelectFilterProps {
  selectedLocation?: string
  setSelectedLocation: (newString: string) => void
  allLocationsSelectedText?: string
  analyticsKey?: string
}

export function LocationSingleSelectFilter({
  selectedLocation = SELECT_ALL_OPTION_ID,
  setSelectedLocation,
  allLocationsSelectedText = SELECT_LOCATION_TEXT,
  analyticsKey,
}: LocationSingleSelectFilterProps) {
  const {
    selectedActiveLocationIds,
    isLoading: isRegionalFilterLoading,
    activeRegionIdsToLocationsMap,
  } = useSelectedRegionalFilterLocations()
  const { isLoading: isRegionsLoading, regionMap } = useValidRegionsMap()

  const onChangeLocationFilter = useCallback(
    (locationId: string | undefined) => {
      setSelectedLocation(locationId ?? SELECT_ALL_OPTION_ID)
      window.analytics.track(
        `User Updated Locations Filter${analyticsKey ? ` for ${analyticsKey}` : ''}`,
        { locationId },
      )
    },
    [setSelectedLocation],
  )

  const isAllLocationsSelected = selectedLocation === SELECT_ALL_OPTION_ID
  const isCompanyWideSelected = selectedLocation === COMPANY_WIDE_ID
  const isSelectedLocationNoLongerInRegionalFilterSelectedLocations =
    !isAllLocationsSelected &&
    !isCompanyWideSelected &&
    !selectedActiveLocationIds.has(selectedLocation)

  useEffect(() => {
    if (isSelectedLocationNoLongerInRegionalFilterSelectedLocations) {
      setSelectedLocation(SELECT_ALL_OPTION_ID)
    }
  }, [
    isSelectedLocationNoLongerInRegionalFilterSelectedLocations,
    setSelectedLocation,
  ])

  return (
    <GroupedLocationSingleSearchSelector
      selectedLocationId={selectedLocation}
      onChange={onChangeLocationFilter}
      regionMap={regionMap}
      regionToLocationsMap={activeRegionIdsToLocationsMap}
      isLoading={isRegionalFilterLoading || isRegionsLoading}
      placeholder="Location"
      label="Location"
      style={{ width: '100%', maxWidth: '480px' }}
      selectStyle={{ height: '48px' }}
      selectAllOptionLabel={allLocationsSelectedText}
      showSelectAllOption
      companyWideOptionSecondaryLabel=""
    />
  )
}

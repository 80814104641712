import { AlertColor } from '@mui/material'
import { useAlert } from '@traba/context'
import { makePlural } from '@traba/string-utils'
import {
  Shift,
  ShiftRequest,
  ShiftRequestEditType,
  UpdateShift,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RoleInfoForCreateShiftRequest,
  UserAccessLevel,
} from '@traba/types'
import {
  combineRecurringShifts,
  getFilteredShiftRequestsByAssignedLocations,
  getRecurringShifts,
  isRecurringShiftRequest,
  recurringSchedulesEnabled,
} from '@traba/utils'
import { addMinutes, set } from 'date-fns'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkersOnShift } from 'src/components/WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { useCompany } from 'src/hooks/useCompany'
import { useCompanyEmploymentType } from 'src/hooks/useCompanyEmploymentType'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { createInvitation, useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import { useShiftInvitations } from 'src/hooks/useShiftInvitations'
import {
  useBookShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import { updateShiftById, useUpdateShifts } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { useWorkersByIds } from 'src/hooks/useWorker'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'
import { getTimeAfterTimeWithin24Hours } from 'src/shared/utils/dateUtils'
import { UpdateShiftData } from 'src/types'
import { WebToRNEventName } from 'src/types/events'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import { getRoleInfoForCreateShiftRequest } from '../AddToExistingSchedule/utils'
import { useEditShift } from '../EditShifts/EditShifts.hooks'
import { UpdateSlotsRequestedProps } from '../EditShifts/EditWorkerSlotsModal'
import {
  getChangesFromStep,
  hasAdditionalShiftInvitations,
} from '../EditShifts/utils'
import { BookShiftsConfirmContent } from './steps/BookShiftsConfirmContent'
import { BookShiftsConfirmContentShiftDataModel } from './steps/BookShiftsConfirmContentShiftDataModel'
import { BookShiftsDetailsContent } from './steps/BookShiftsDetailsContent'
import { BookShiftsDetailsContentShiftDataModel } from './steps/BookShiftsDetailsContentShiftDataModel'
import { BookShiftsInvoiceContent } from './steps/BookShiftsInvoiceContent'
import { BookShiftsScheduleContent } from './steps/BookShiftsScheduleContent'
import { BookShiftsWorkersContent } from './steps/BookShiftsWorkersContent'
import { BookShiftsWorkersContentShiftDataModel } from './steps/BookShiftsWorkersContentShiftDataModel'
import { EditOperation } from './types'
import { createBulkShiftRequestSchedules } from './utils'
import {
  ALL_READABLE_KEYS,
  AllShiftKeys,
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  validateInvoiceStep,
  validateScheduleStep,
  validateScheduleStepShiftDataModel,
  validateSiteStep,
  validateSiteStepShiftDataModel,
  validateWorkersStep,
  validateWorkersStepShiftDataModel,
} from './validation'

type Step = {
  title: string
  Component: any
  validate?: (
    data: CreateShiftRequest,
    metadata: CreateShiftRequestMetadata,
  ) =>
    | {
        message: string
        title: string
      }
    | undefined
  hideInSideBar?: boolean
  type: StepType
}

export enum StepType {
  SUMMARY = 'SUMMARY',
  DETAILS = 'DETAILS',
  SCHEDULE = 'SCHEDULE',
  WORKERS = 'WORKERS',
  INVOICE = 'INVOICE',
  CONFIRM = 'CONFIRM',
}

export enum ScheduleEditType {
  SCHEDULE = 'SCHEDULE',
  ADD_TO_EXISTING = 'ADD_TO_EXISTING',
}

const EDIT_STEPS: Step[] = [
  {
    title: 'SUMMARY',
    Component: BookShiftsConfirmContent,
    type: StepType.SUMMARY,
  },
  {
    title: 'Shift Details',
    Component: BookShiftsDetailsContent,
    type: StepType.DETAILS,
  },
  {
    title: 'Workers',
    Component: BookShiftsWorkersContent,
    type: StepType.WORKERS,
  },
  {
    title: 'Schedule',
    Component: BookShiftsScheduleContent,
    type: StepType.SCHEDULE,
  },
]

export function getBookSteps(minHourlyPayRate: number): Step[] {
  return [
    {
      title: 'Shift Details',
      Component: BookShiftsDetailsContent,
      validate: validateSiteStep,
      type: StepType.DETAILS,
    },
    {
      title: 'Schedule',
      Component: BookShiftsScheduleContent,
      validate: validateScheduleStep,
      type: StepType.SCHEDULE,
    },
    {
      title: 'Workers',
      Component: BookShiftsWorkersContent,
      validate: (data: CreateShiftRequest) =>
        validateWorkersStep(data, minHourlyPayRate),
      type: StepType.WORKERS,
    },
    {
      title: 'Confirm',
      Component: BookShiftsConfirmContent,
      type: StepType.CONFIRM,
    },
  ]
}

export function getBookStepsShiftDataModel(minHourlyPayRate: number): Step[] {
  const { showInvoicing } = useCompanyEmploymentType()
  const { members } = useMembers()

  return [
    {
      title: 'Location',
      Component: BookShiftsDetailsContentShiftDataModel,
      validate: validateSiteStepShiftDataModel,
      type: StepType.DETAILS,
    },
    {
      title: 'Schedule',
      Component: BookShiftsScheduleContent,
      validate: validateScheduleStepShiftDataModel,
      type: StepType.SCHEDULE,
    },
    {
      title: 'Workers',
      Component: BookShiftsWorkersContentShiftDataModel,
      validate: (
        data: CreateShiftRequest,
        metadata: CreateShiftRequestMetadata,
      ) =>
        validateWorkersStepShiftDataModel({
          data,
          minHourlyPay: minHourlyPayRate,
          recurringRoles: metadata.recurringRoles,
          role: metadata.role,
          companyUsers: members,
        }),
      type: StepType.WORKERS,
    },
    ...(showInvoicing
      ? [
          {
            title: 'Invoicing',
            Component: BookShiftsInvoiceContent,
            validate: validateInvoiceStep,
            type: StepType.INVOICE,
          },
        ]
      : []),
    {
      title: 'Confirm',
      Component: BookShiftsConfirmContentShiftDataModel,
      type: StepType.CONFIRM,
    },
  ]
}

export const useBookShiftsScreen = ({
  shiftRequestTemplate,
  shiftTemplate,
  isEdit,
  editOperation,
  shiftRequests,
  ignoreRecurrence,
  shiftRequestsInParent,
}: {
  shiftRequestTemplate?: ShiftRequest
  shiftTemplate?: Shift
  isEdit?: boolean
  editOperation?: EditOperation
  shiftRequests?: ShiftRequest[]
  ignoreRecurrence?: boolean
  shiftRequestsInParent?: ShiftRequest[]
}) => {
  const { isReactNativeApp } = useMobile()

  const {
    defaultShiftRequest,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
  } = useBookShiftRequest({
    shiftRequestTemplate,
    shiftTemplate,
    isEdit,
    ignoreRecurrence,
  })
  const { bulkCreateShiftRequest } = useShiftRequests()
  const { updateShift } = useUpdateShifts()
  const { refetch: refetchShift } = useEditShift(
    shiftTemplate?.shiftId,
    false /* fetchShift */,
  )
  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState(false)
  const [selectedSingleShiftDates, setSelectedSingleShiftDates] = useState<
    Date[] | null
  >(null)
  const { company, patchCompany } = useCompany()
  const { members } = useMembers()
  const { hotSettings } = useHotSettings()

  const siteDataValidationError =
    shiftRequestTemplate && validateSiteStep(defaultShiftRequest)

  const minHourlyPay = getMinHourlyPayRate({
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
    companyMinHourlyPay: company?.minHourlyPayRate,
  })

  const steps = isEdit
    ? EDIT_STEPS
    : recurringSchedulesEnabled({
          companyId: company?.companyId,
          hotSettings,
        })
      ? getBookStepsShiftDataModel(minHourlyPay)
      : getBookSteps(minHourlyPay)

  const [activeStep, setActiveStep] = useState(
    !isEmpty(shiftRequestTemplate) && !siteDataValidationError && !isEdit
      ? steps.length - 1
      : 0,
  )
  const [completedThroughStep, setCompletedThroughStep] = useState(
    !isEmpty(shiftRequestTemplate) && !siteDataValidationError && !isEdit
      ? steps.length - 1
      : -1,
  )
  const [showSelectModal, setShowSelectModal] = useState<boolean>()
  const [selectedShifts, setSelectedShifts] = useState<Shift[]>(
    shiftTemplate ? [shiftTemplate] : [],
  )
  const [editsResult, setEditsResult] = useState<
    | {
        title: string
        severity: AlertColor
        subTitle?: string
      }
    | undefined
  >()

  // Only show the ability to rebook at locations the user has access to
  // & is present in their current global regional filter
  const { selectedActiveLocationIds } = useSelectedRegionalFilterLocations()
  const filteredShiftRequestsByUserAccess = shiftRequests
    ? getFilteredShiftRequestsByAssignedLocations({
        shiftRequests: shiftRequests,
        assignedLocationIds: selectedActiveLocationIds,
      })
    : undefined
  const [isSelectShiftRequestModalOpen, setIsSelectShiftRequestModalOpen] =
    useState<boolean | undefined>(
      !shiftRequestTemplate &&
        !isEmpty(filteredShiftRequestsByUserAccess) &&
        !isReactNativeApp,
    )

  const [isEditSlotsModalOpen, setIsEditSlotsModalOpen] =
    useState<boolean>(false)

  const scrollContainer = useRef<HTMLDivElement>(null)
  const { showError, showSuccess } = useAlert()
  const navigate = useNavigate()
  const [isBookingInDraft, setIsBookingInDraft] = useState(true)

  const activeStepRef = useRef(activeStep)
  const isBookingInDraftRef = useRef(isBookingInDraft)

  const shiftCount = useMemo(() => {
    const [scheduleA, scheduleB] = shiftRequest.schedules
    if (!scheduleA.isRecurringSchedule) {
      return 1
    }
    const recurringShiftsA = getRecurringShifts(scheduleA)
    if (scheduleB) {
      return combineRecurringShifts(
        recurringShiftsA,
        getRecurringShifts(scheduleB),
      ).shiftCount
    }
    return recurringShiftsA.shiftCount
  }, [shiftRequest.schedules])

  const shiftUpdates: Partial<Shift | undefined> = useMemo(() => {
    const step = steps[activeStep]
    return getChangesFromStep(step.type, defaultShiftRequest, shiftRequest)
  }, [activeStep, defaultShiftRequest, shiftRequest, steps])

  const {
    shiftInvitations: existingShiftInvitations,
    sendInvitationsAsync: sendInvitations,
  } = useShiftInvitations({
    shiftIds: selectedShifts[0]?.shiftId ? [selectedShifts[0]?.shiftId] : [],
    isEnabled: !!isEdit && !!selectedShifts[0]?.shiftId,
  })
  const { getWorkerById } = useWorkersByIds(
    existingShiftInvitations.map((invitation) => invitation.workerId),
  )

  // If editing shift & decreasing slots below slotsFilled, we need to show the editSlots modal
  const shiftsRequiringWorkerRemoval = useMemo(() => {
    return isEdit && shiftUpdates?.slotsRequested
      ? selectedShifts.filter((shift) => {
          if (shiftUpdates?.slotsRequested) {
            return (
              shiftUpdates.slotsRequested <
              Math.min(shift.slotsFilled, shift.slotsRequested)
            )
          }
          return false
        })
      : []
  }, [isEdit, selectedShifts, shiftUpdates?.slotsRequested])

  /* We only need to load workerShifts here if displaying the editSlots modal to select workers
  to remove and only one shift has been selected to edit. If multiple shifts to edit, we auto
  select workers to remove. */
  const shouldQueryWorkersAndWorkerShifts = useMemo(() => {
    return (
      shiftsRequiringWorkerRemoval.length === 1 && selectedShifts.length === 1
    )
  }, [shiftsRequiringWorkerRemoval, selectedShifts])

  const { data: workerShifts = [] } = useWorkerShifts(
    selectedShifts[0]?.shiftId,
    shouldQueryWorkersAndWorkerShifts /* isEnabled */,
  )
  const { data: workers } = useCompanyWorkersByIds({
    isEnabled: shouldQueryWorkersAndWorkerShifts,
  })
  const { workersOnShift, workersOnBackup } = useWorkersOnShift({
    isUpcoming: true,
    workerShifts,
    slotsRequested: defaultShiftRequest?.slotsRequested ?? 0,
  })

  const handleUpdateSlotsRequested = async (
    props: UpdateSlotsRequestedProps,
  ) => {
    const { autoRemoveWorkersFromShift, workerIdsToRemove } = props
    const shiftUpdatesToSave = validateShiftUpdates()
    if (shiftUpdatesToSave) {
      const shiftUpdates = {
        ...shiftUpdatesToSave,
        ...(autoRemoveWorkersFromShift && {
          removeWorkersFromShift: { shouldAutoRemoveWorkers: true },
        }),
        ...(workerIdsToRemove && {
          removeWorkersFromShift: {
            workerIdsToRemove: workerIdsToRemove,
          },
        }),
      }
      await handleSaveChanges(shiftUpdates)
    }
  }

  useEffect(() => {
    if (
      isEdit &&
      defaultShiftRequest?.schedules[0].isRecurringSchedule &&
      editOperation === EditOperation.SERIES
    ) {
      setShowSelectModal(true)
    }
  }, [])

  useEffect(() => {
    activeStepRef.current = activeStep
  }, [activeStep])

  useEffect(() => {
    isBookingInDraftRef.current = isBookingInDraft
  }, [isBookingInDraft])

  function onNavigate(step: number) {
    // Too far back
    if (step < 0) {
      return
    }
    // Too far forward
    if (step >= steps.length) {
      return
    }
    // Validate if advancing completion or active step already completed
    const shouldValidate =
      step > activeStep || activeStep <= completedThroughStep
    if (shouldValidate) {
      const { validate } = steps[activeStep]
      if (validate) {
        const error = validate(shiftRequest, {
          ...shiftRequestMetadata,
          recurringRoles,
        })
        if (error) {
          showError(error.message, error.title)
          return
        }
      }
    }
    const newCompletedThroughStep = Math.max(step - 1, completedThroughStep)
    // Past completion point
    if (step > newCompletedThroughStep + 1) {
      return
    }
    // Update the step and completion point, if needed
    setActiveStep(step)
    setCompletedThroughStep(newCompletedThroughStep)
    // Scroll to top upon step change
    scrollContainer.current?.scroll(0, 0)
    // Track the user's progress
    window.analytics.track(
      `User Navigated to Shift Booking ${steps[step].title} Step`,
    )
  }

  function onContinue() {
    onNavigate(activeStep + 1)
    window.scrollTo(0, 0)
  }

  function onPrevious() {
    setActiveStep(activeStep - 1)
    window.scrollTo(0, 0)
  }

  async function onBook({ isRebooking }: { isRebooking: boolean }) {
    setIsCreatingOrUpdating(true)
    window.analytics.track(`User Submitted Shift Booking Request`, {
      ...shiftRequest,
      isRebooking,
    })

    const errors = steps
      .map((s) =>
        s.validate
          ? s.validate(shiftRequest, {
              ...shiftRequestMetadata,
              recurringRoles,
            })
          : undefined,
      )
      .filter((e) => !!e)

    if (errors[0]) {
      showError(errors[0].message, errors[0].title)
      return
    }

    if (!company?.agreements?.acceptedFeesAndTerms) {
      await patchCompany({
        agreements: {
          acceptedFeesAndTerms: shiftRequestMetadata.acceptedFeesAndTerms,
        },
      })
    }

    const bulkShiftRequestSchedules = createBulkShiftRequestSchedules(
      selectedSingleShiftDates,
      shiftRequest.schedules,
    )

    const bulkShiftRequests = recurringSchedulesEnabled({
      companyId: company?.companyId,
      hotSettings,
    })
      ? (recurringRoles.flatMap((role) =>
          bulkShiftRequestSchedules.map((schedules) => ({
            ...shiftRequest,
            ...role,
            schedules,
          })),
        ) ?? recurringRoles.map((role) => ({ ...shiftRequest, ...role })))
      : [shiftRequest]

    const response = await bulkCreateShiftRequest(
      bulkShiftRequests,
      shiftRequestMetadata,
    )

    if (shiftRequestMetadata.invitedContactRole) {
      const member = members.find((m) => m.uid === shiftRequest.supervisorId)
      if (member?.email) {
        window.analytics.track('User Invited Contact During Shift Creation', {
          member: member.uid,
          role: shiftRequestMetadata.invitedContactRole,
        })
        await createInvitation({
          email: member.email,
          role: shiftRequestMetadata.invitedContactRole,
          invitedUserId: member.uid,
          // For now, we'll default to company-wide when inviting contacts & can reevaluate later.
          // If we do assign user access & locations in the future,
          // we'll also need to validate they have access to the location of this shift
          // & all locations of future shifts they are assigned to
          userAccessLevel: UserAccessLevel.COMPANY_WIDE,
          locationIds: [],
        })
      }
    }
    setIsCreatingOrUpdating(false)
    if (response?.status === 201) {
      setIsBookingInDraft(false)
      if (isReactNativeApp) {
        return window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: WebToRNEventName.SHIFT_BOOKED,
          }),
        )
      }
      const { data } = response
      if (
        data.shiftRequests.length > 0 &&
        data.shiftRequests[0].shiftRequestParentId
      ) {
        navigate(`/schedule/${data.shiftRequests[0].shiftRequestParentId}`, {
          state: {
            modalType: 'create-shift-success',
            shiftCount,
          },
        })
      } else {
        navigate(`/calendar/${data.shiftRequests[0].firstShiftId}`, {
          state: {
            modalType: 'create-shift-success',
            shiftCount,
          },
        })
      }
    }
  }

  // If the shift has a businessStartTime, we should maintain the early arrival buffer when
  // updating the shift startTime
  function calculateStartTimeUpdates(shift?: Shift, updatedStartTime?: Date) {
    if (shift === undefined || updatedStartTime === undefined) {
      return {}
    }
    const eatBufferForShiftTemplate =
      (shiftTemplate && getEarlyArrivalTimeBufferInMinutes(shiftTemplate)) ?? 0
    const eatBuffer = getEarlyArrivalTimeBufferInMinutes(shift) ?? 0

    // for multi-shift edit, this handles the case where one of the shifts
    // has a different buffer than the shift rendered on the edit page
    const differenceInBuffer = eatBufferForShiftTemplate - eatBuffer

    const newStartTime = addMinutes(
      set(updatedStartTime, {
        year: shift.startTime.getFullYear(),
        month: shift.startTime.getMonth(),
        date: shift.startTime.getDate(),
      }),
      differenceInBuffer,
    )
    const updates: Partial<Shift> = {
      startTime: newStartTime,
      endTime: getTimeAfterTimeWithin24Hours(shift.endTime, newStartTime),
    }

    if (shift.businessStartTime) {
      updates.businessStartTime = addMinutes(newStartTime, eatBuffer)
    }

    return updates
  }

  async function handleSaveChanges(shiftUpdates: Partial<Shift>) {
    setIsCreatingOrUpdating(true)
    const shiftIds = selectedShifts.map((s) => s.shiftId)
    const shiftsPluralize = `shift${shiftIds.length > 1 ? 's' : ''}`
    const updateKeys =
      shiftUpdates && (Object.keys(shiftUpdates) as AllShiftKeys[])

    let stringifiedFields = updateKeys
      .map((key) => ALL_READABLE_KEYS[key])
      .filter((k) => !!k)
      .join(`, `)

    const prevInvitedWorkers = existingShiftInvitations.flatMap(
      (invitation) => invitation.workerId,
    )
    const workerIdsToInvite = (
      shiftRequest.shiftInvitations?.flatMap((invitation) =>
        invitation.workers.map((worker) => worker.workerId),
      ) ?? []
    ).filter((workerId) => !prevInvitedWorkers.includes(workerId))

    if (workerIdsToInvite.length) {
      await sendInvitations({
        shiftId: shiftIds[0],
        workerIds: workerIdsToInvite,
      })
      // Clear newly invited workers
      setShiftRequest({
        shiftInvitations: defaultShiftRequest.shiftInvitations,
      })
      if (!shiftUpdates || isEmpty(shiftUpdates)) {
        setEditsResult({
          title: `Successfully Updated Shift Invitations`,
          subTitle: `Updated shift invitations for ${
            shiftIds.length
          } shift${makePlural(
            shiftIds.length,
          )}. Would you like to make any more edits?`,
          severity: `success`,
        })
        setIsBookingInDraft(false)
        setIsCreatingOrUpdating(false)
        refetchShift()
        setTimeout(() => onNavigate(0), 800)
        return
      }
      stringifiedFields += stringifiedFields
        ? ', shift invitations'
        : 'shift invitations'
    }

    let shiftUpdateData: UpdateShift | undefined
    let shiftUpdateDataPerShift: UpdateShiftData[] = []
    switch (editOperation) {
      case EditOperation.ALL_FUTURE:
        shiftUpdateData = {
          ...shiftUpdates,
          editType: ShiftRequestEditType.ALL_FUTURE,
        }
        break
      case EditOperation.SINGLE:
        shiftUpdateData = {
          ...shiftUpdates,
          ...calculateStartTimeUpdates(shiftTemplate, shiftUpdates.startTime),
          editType: ShiftRequestEditType.SINGLE,
        }
        break
      case EditOperation.SERIES:
      case undefined:
      case null:
        if (shiftUpdates.startTime === undefined) {
          shiftUpdateData = shiftUpdates
        } else {
          shiftUpdateDataPerShift = selectedShifts.map((shift) => {
            return {
              shiftId: shift.shiftId,
              ...shiftUpdates,
              ...calculateStartTimeUpdates(shift, shiftUpdates.startTime),
            }
          })
        }
        break
    }

    let batchUpdateResponse
    let individuallyUpdateResponses
    if (shiftUpdateData) {
      batchUpdateResponse = await updateShift(shiftIds, shiftUpdateData)
    } else {
      // We need to make individual updates when we need to update shifts with different values
      individuallyUpdateResponses = await Promise.allSettled(
        shiftUpdateDataPerShift.map(updateShiftById),
      )
    }

    const individualUpdateSuccesses =
      individuallyUpdateResponses?.filter(
        (response) => response.status === 'fulfilled',
      ).length ?? 0
    const individualUpdateFailures =
      individuallyUpdateResponses?.filter(
        (response) => response.status === 'rejected',
      ).length ?? 0

    if (shiftRequestMetadata.invitedContactRole) {
      const member = members.find((m) => m.uid === shiftRequest.supervisorId)
      if (member?.email) {
        window.analytics.track('User Invited Contact During Shift Edits', {
          member: member.uid,
          role: shiftRequestMetadata.invitedContactRole,
        })
        await createInvitation({
          email: member.email,
          role: shiftRequestMetadata.invitedContactRole,
          invitedUserId: member.uid,
          // For now, we'll default to company-wide when inviting contacts & can reevaluate later.
          // If we do assign user access & locations in the future,
          // we'll also need to validate they have access to the location of this shift
          // & all locations of future shifts they are assigned to
          userAccessLevel: UserAccessLevel.COMPANY_WIDE,
          locationIds: [],
        })
      }
    }

    setIsCreatingOrUpdating(false)
    if (
      batchUpdateResponse?.status === 200 ||
      (individuallyUpdateResponses &&
        individualUpdateSuccesses === shiftUpdateDataPerShift.length)
    ) {
      showSuccess(`Successfully updated ${shiftsPluralize}!`)
      window.analytics.track(`User Edited Shift(s)`, {
        ...shiftUpdates,
        shiftIds,
      })
      setEditsResult({
        title: `Successfully Updated ${shiftIds.length} ${shiftsPluralize}`,
        subTitle: `Updated shift's ${stringifiedFields} for ${shiftIds.length} shifts. Would you like to make any more edits?`,
        severity: `success`,
      })
      setIsBookingInDraft(false)
      refetchShift()
      setTimeout(() => onNavigate(0), 800)
    } else if (
      batchUpdateResponse?.status === 206 ||
      individualUpdateSuccesses > 0
    ) {
      // partial success
      const partialSuccess =
        batchUpdateResponse !== undefined
          ? (batchUpdateResponse as {
              status: number
              succeeded: string[]
              rejected: string[]
            })
          : undefined

      const succeeded = partialSuccess?.succeeded ?? individualUpdateSuccesses
      const rejected = partialSuccess?.rejected ?? individualUpdateFailures

      window.analytics.track(`User Edited Shift(s)`, {
        ...shiftUpdates,
        shiftIds,
        succeeded,
        rejected,
      })

      setEditsResult({
        title: `Successfully Updated ${succeeded} ${shiftsPluralize}. Failed to update ${rejected} ${shiftsPluralize}`,
        subTitle: `Updated shift ${stringifiedFields} for ${succeeded} ${shiftsPluralize}. Would you like to make any more edits?`,
        severity: `info`,
      })
      setIsBookingInDraft(false)
      refetchShift()
      setTimeout(() => onNavigate(0), 800)
    } else {
      setEditsResult(undefined)
    }
  }

  const validateShiftUpdates = (): Partial<Shift> | undefined => {
    const step = steps[activeStep]
    if (
      (!shiftUpdates || isEmpty(shiftUpdates)) &&
      !hasAdditionalShiftInvitations(defaultShiftRequest, shiftRequest)
    ) {
      showError(
        "Looks like you haven't made any changes, adjust something to save!",
        'No changes made',
      )
      return
    }
    const error =
      step.validate && step.validate(shiftRequest, shiftRequestMetadata)
    if (error) {
      showError(error.message, error.title)
      return
    }
    return shiftUpdates
  }

  async function onSaveChanges() {
    const shiftUpdates = validateShiftUpdates()

    if (shiftsRequiringWorkerRemoval.length > 0) {
      setIsEditSlotsModalOpen(true)
      return
    }

    shiftUpdates && (await handleSaveChanges(shiftUpdates))
  }

  const editSlotsModalProps = {
    isEditSlotsModalOpen,
    setIsEditSlotsModalOpen,
    workersOnShift,
    workersOnBackup,
    workers,
    handleUpdateSlotsRequested,
    shiftsRequiringWorkerRemoval,
  }

  const [recurringRoles, setRecurringRoles] = useState<
    RoleInfoForCreateShiftRequest[]
  >(() =>
    shiftRequestsInParent
      ? shiftRequestsInParent.filter(isRecurringShiftRequest).map((sr) =>
          getRoleInfoForCreateShiftRequest({
            shiftRequest: sr,
            copyRoleId: true,
          }),
        )
      : [
          getRoleInfoForCreateShiftRequest({
            shiftRequest: shiftRequestTemplate ?? shiftRequest,
            copyRoleId: true,
          }),
        ],
  )

  function addNewRole() {
    const newRole = getRoleInfoForCreateShiftRequest({ shiftRequest })
    setRecurringRoles((prev) => [...prev, newRole])
  }

  function removeRole(roleId: string) {
    setRecurringRoles((prev) => prev.filter((r) => r.roleId !== roleId))
  }

  function updateRoleInfoForCreateShiftRequest(
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) {
    setRecurringRoles((prevRecurringRoles) => {
      if (originalRoleId) {
        return prevRecurringRoles.map((r) =>
          r.roleId === originalRoleId
            ? updatedRoleInfoForCreateShiftRequest
            : r,
        )
      } else {
        let replaced = false
        return prevRecurringRoles.map((r) => {
          if (!r.roleId && !replaced) {
            replaced = true
            return updatedRoleInfoForCreateShiftRequest
          }
          return r
        })
      }
    })
  }

  return {
    steps,
    activeStep,
    activeStepRef,
    onBook,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    isBookingInDraft,
    isBookingInDraftRef,
    scrollContainer,
    completedThroughStep,
    defaultShiftRequest,
    onNavigate,
    onContinue,
    onPrevious,
    onSaveChanges,
    selectedShifts,
    setSelectedShifts,
    showSelectModal,
    setShowSelectModal,
    shiftUpdates,
    editsResult,
    isSelectShiftRequestModalOpen,
    setIsSelectShiftRequestModalOpen,
    isCreatingOrUpdating,
    editSlotsModalProps,
    existingShiftInvitations,
    getWorkerById,
    selectedSingleShiftDates,
    setSelectedSingleShiftDates,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    updateRoleInfoForCreateShiftRequest,
    removeRole,
  }
}

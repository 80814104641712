import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { SupervisorForShiftSearchSelect } from '@traba/react-components'
import { ShiftRequestParentWithShiftRequest, ShiftStatus } from '@traba/types'
import { getMembersWithAccessToAnyGivenLocations } from '@traba/utils'
import { useCallback, useMemo } from 'react'
import { Text } from 'src/components'
import { ShowWorkersToggle } from 'src/components/ShiftCalendar/ShowWorkersToggle'
import { FilterDropdown } from 'src/components/ShiftFilters/FilterDropdown'
import { useMembers } from 'src/hooks/useMembers'
import useMobile from 'src/hooks/useMobile'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'
import {
  CalendarFilters as CalendarFiltersType,
  FiltersAction,
} from 'src/hooks/useShiftFilters.hooks'
import { theme } from 'src/libs/theme'

interface Props {
  dispatch: React.Dispatch<FiltersAction>
  filters: CalendarFiltersType
  isShowWorkersToggled?: boolean
  setIsShowWorkersToggled?: React.Dispatch<React.SetStateAction<boolean>>
  shiftRequestParents?: ShiftRequestParentWithShiftRequest[]
}

export const CalendarFilters = ({
  dispatch,
  filters,
  isShowWorkersToggled,
  setIsShowWorkersToggled,
  shiftRequestParents,
}: Props) => {
  const { members } = useMembers()
  const { isMobileViewOrReactNative } = useMobile()
  const { hotSettings } = useHotSettings()
  const { selectedActiveLocationIds } = useSelectedRegionalFilterLocations()
  const supervisorsForLocations = useMemo(() => {
    return getMembersWithAccessToAnyGivenLocations({
      locationIds: selectedActiveLocationIds,
      members,
    })
  }, [members, selectedActiveLocationIds])

  const onChangeSupervisor = useCallback(
    (supervisorId: string) =>
      dispatch({ type: 'SET_SUPERVISOR', supervisorId }),
    [dispatch],
  )

  return (
    <>
      <Text variant="h5" mt={theme.space.med} mb={theme.space.xs}>
        Filter by
      </Text>
      <SupervisorForShiftSearchSelect
        placeholder="Onsite contact"
        selectedSupervisorId={filters.supervisorId}
        allSupervisors={supervisorsForLocations}
        handleSupervisorChange={onChangeSupervisor}
        disabled={members.length === 0}
        selectStyle={{ height: '48px' }}
        style={{ maxWidth: '100%', marginBottom: theme.space.xs }}
      />
      {shiftRequestParents && hotSettings?.recurringSchedulesEnabled && (
        <FilterDropdown
          label="Schedule name"
          selected={filters.shiftRequestParentIds?.[0] ?? undefined}
          items={shiftRequestParents
            .map((parent) => ({
              value: parent.shiftRequestParentId,
              label: `${parent.title}`,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1))}
          onSelectItem={(value) =>
            dispatch({
              type: 'SET_SHIFT_REQUEST_PARENT',
              shiftRequestParentIds: value ? [value] : undefined,
            })
          }
        />
      )}
      <FormGroup
        style={{
          marginRight: theme.space.xs,
          marginTop: theme.space.xs,
        }}
      >
        <FormControlLabel
          labelPlacement="start"
          style={{ marginLeft: 0 }}
          control={
            <Switch
              checked={filters.statuses === undefined}
              onChange={() =>
                dispatch({
                  type: 'SET_STATUSES',
                  statuses:
                    filters.statuses === undefined
                      ? [ShiftStatus.ACTIVE, ShiftStatus.COMPLETE]
                      : undefined,
                })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={
            <Text variant="h6" color={theme.colors.MidnightBlue}>
              Show canceled shifts
            </Text>
          }
        />
      </FormGroup>
      {isMobileViewOrReactNative &&
        isShowWorkersToggled !== undefined &&
        setIsShowWorkersToggled && (
          <ShowWorkersToggle
            isShowWorkersToggled={isShowWorkersToggled}
            setIsShowWorkersToggled={setIsShowWorkersToggled}
          />
        )}
    </>
  )
}
